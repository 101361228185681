.logo {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
}

code {
    font-family: Consolas, Menlo, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    -webkit-font-smoothing: subpixel-antialiased;
    padding: 0px 5px;
    border-radius: 5px;
    background: #444;
}
