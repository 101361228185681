.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  :global(html.fullscreen) .header {
    top: 64px;
  }
}

.header :global(.wallet-adapter-button) {
  margin: 16px;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.total {
}

.amount {
  line-height: 64px;
  font-size: 48px;
  font-weight: 700;
}

.code {
  margin: 48px 24px;
}

.symbol {
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}

.scan {
  font-weight: 700;
  line-height: 24px;
}

.confirm {
  line-height: 24px;
}

.footer {
  text-align: center;
}
