import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { TorusWalletAdapter } from '@solana/wallet-adapter-torus';
import { PublicKey } from '@solana/web3.js';
import React, { FC, useMemo } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { DEVNET_ENDPOINT } from '../../utils/constants';
import { ConfigProvider } from '../contexts/ConfigProvider';
import { FullscreenProvider } from '../contexts/FullscreenProvider';
import { PaymentProvider } from '../contexts/PaymentProvider';
import { ThemeProvider } from '../contexts/ThemeProvider';
import { TransactionsProvider } from '../contexts/TransactionsProvider';
import { SolanaPayLogo } from '../images/SolanaPayLogo';
import { SOLIcon } from '../images/SOLIcon';
import * as css from './RootRoute.module.pcss';
import { MAINNET_ENDPOINT, MAINNET_USDC_MINT } from '../../utils/constants';
import { USDCIcon } from '../images/USDCIcon';

import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material/styles';
import MUIThemeProvider from '@mui/material/styles/ThemeProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  darken,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PhotoCamera } from '@mui/icons-material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import queryString from 'query-string';

export const RootRoute: FC = () => {
  // If you're testing without a phone, set this to true to allow a browser-based wallet connection to be used
  const connectWallet = false;
  const wallets = useMemo(
    () => (connectWallet ? [new PhantomWalletAdapter(), new TorusWalletAdapter()] : []),
    [connectWallet]
  );

  const [params] = useSearchParams();
  const { recipient, label } = useMemo(() => {
    let recipient: PublicKey | undefined, label: string | undefined;

    const recipientParam = params.get('recipient');
    const labelParam = params.get('label');
    if (recipientParam && labelParam) {
      try {
        recipient = new PublicKey(recipientParam);
        label = labelParam;
      } catch (error) {
        console.error(error);
      }
    }

    return { recipient, label };
  }, [params]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            // light: will be calculated from palette.primary.main,
            main: darken('#8C7CF0', 0.1),
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
          },
        },
      }),
    [prefersDarkMode]
  );
  const [isSolanaAddressModalOpen, setIsSolanaAddressModalOpen] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [solanaPublicAddress, setSolanaPublicAddress] = useState('');

  const navigate = useNavigate();

  const createPaymentTerminal = () => {
    navigate(
      '/new?' +
        queryString.stringify({
          recipient: solanaPublicAddress,
          label: businessName,
        })
    );
  };

  return (
    <MUIThemeProvider theme={theme}>
      <ThemeProvider>
        <FullscreenProvider>
          {recipient && label ? (
            <ConnectionProvider endpoint={MAINNET_ENDPOINT}>
              <WalletProvider wallets={wallets} autoConnect={connectWallet}>
                <WalletModalProvider>
                  <ConfigProvider
                    recipient={recipient}
                    label={label}
                    splToken={MAINNET_USDC_MINT}
                    symbol="USDC"
                    icon={<USDCIcon />}
                    decimals={6}
                    minDecimals={2}
                    connectWallet={connectWallet}
                  >
                    <TransactionsProvider>
                      <PaymentProvider>
                        <Outlet />
                      </PaymentProvider>
                    </TransactionsProvider>
                  </ConfigProvider>
                </WalletModalProvider>
              </WalletProvider>
            </ConnectionProvider>
          ) : (
            <div
              className={css.logo}
              style={{
                padding: '12px',
              }}
            >
              <SolanaPayLogo width={240} height={88} />

              <Box
                sx={{
                  width: 500,
                  maxWidth: '100%',
                  marginTop: '32px',
                }}
              >
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label="Business Name"
                    id="Business Name"
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                  <TextField
                    fullWidth
                    label="Solana Public Key"
                    id="Solana Public Key"
                    onChange={(e) => setSolanaPublicAddress(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setIsSolanaAddressModalOpen(true)}
                          >
                            <HelpOutlineIcon></HelpOutlineIcon>
                          </IconButton>
                        </InputAdornment>
                      ),
                      // startAdornment: (
                      //     <label htmlFor="icon-button-file">
                      //         <Input
                      //             accept="image/*"
                      //             id="icon-button-file"
                      //             type="file"
                      //             sx={{ display: 'none' }}
                      //         />
                      //         <IconButton aria-label="scan address QR code" component="span">
                      //             <QrCodeIcon />
                      //         </IconButton>
                      //     </label>
                      // ),
                    }}
                    // TODO:
                    // - button to scan key from QR code
                  />
                  <Button variant="contained" onClick={createPaymentTerminal}>
                    Create Payment Terminal
                  </Button>
                </Stack>
              </Box>

              <Modal
                open={isSolanaAddressModalOpen}
                onClose={() => setIsSolanaAddressModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 700,
                    maxWidth: '90%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography id="modal-modal-title" variant="h5" component="h2">
                    Solana Public Address
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    This is a special code that lets us know where to send your funds.
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    If you don't already have a wallet or account with an exchange that supports
                    USDC on Solana, you can create one with the instructions below and start
                    receiving fee-less cash in no time!
                  </Typography>

                  <Typography sx={{ mt: 4 }} id="modal-modal-title" variant="h6" component="h3">
                    FTX.us
                  </Typography>

                  <Typography>
                    Sign up for an account on{' '}
                    <Link href="https://ftx.us/home/#a=mintaro">
                      FTX.us
                    </Link>{' '}
                    using our promo code, <code>mintaro</code> and get free coins!
                  </Typography>

                  {/* TODO:
                                        - instructions with screenshots to get address
                                        - Crypto.com */}
                </Box>
              </Modal>
            </div>
          )}
        </FullscreenProvider>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};
